<template>
  <v-container class="pa-2">
    <h1 class="text-h4 py-8">🦍 Gorilla: API Zoo 🚀</h1>
    <p>Welcome to the API Zoo, a community-maintained repository of up-to-date API documentation. Our goal is to create and maintain an accessible collection of API documentation that can be utilized by LLMs to extend their capability to use tools through API calls.</p>
    <br>
    <p>If you're interested in contributing to the growth and maintenance of the API Zoo, we encourage you to visit our <a href="https://github.com/ShishirPatil/gorilla/tree/main" target="_blank">GitHub repository</a>. Your contributions can help ensure that the documentation remains current and that new and useful APIs are continually added to our collection. To learn more about how you can contribute, please <a href="https://github.com/ShishirPatil/gorilla/tree/main/data" target="_blank">go here</a>.</p>
    <br>
    <v-text-field
      v-model="search"
      label="Search by api name, contributor, version, or functionality..."
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="apis"
      :search="search"
      item-key="api_name"
      class="elevation-1"
    >
      <template v-slot:[`item.api_name`]="{ item }">
        <a :href="item.file_url" target="_blank">{{ item.api_name }}</a>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn color="primary" @click.stop="() => goToApiDetail(item)">Details</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: '',
    headers: [
      { title: 'API Name', align: 'start', value: 'api_name' },
      { title: 'Contributor', value: 'user_name' },
      { title: 'API Version', align: 'start', value: 'api_version' },
      { title: 'Functionality', value: 'functionality' },
      { title: 'Actions', value: 'action', sortable: false },
    ],
    apis: [],
  }),
  created() {
    this.fetchApis();
  },
  methods: {
    async fetchApis() {
      try {
        const response = await fetch('http://34.123.98.138/api/data');
        if (!response.ok) throw new Error('Failed to fetch');
        const data = await response.json();
        this.apis = data;
      } catch (error) {
        console.error("There was an error fetching the API data:", error);
      }
    },
    goToApiDetail(item) {
      this.$store.dispatch('updateApiDetails', item);
      this.$router.push({ name: 'apiDetail', params: { apiName: item.api_name } });
    },
  },
};
</script>