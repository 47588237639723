<template>
  <v-container class="pa-6">
    <v-card class="ma-5 elevation-2">
      <v-card-title class="headline">
        <v-row no-gutters align="center" justify="space-between">
          <v-col cols="11">{{ apiDetails.api_name }}</v-col>
          <v-col cols="1" class="text-right">
            <a :href="apiDetails.file_url" target="_blank">
              <v-icon large>mdi-github</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="grey--text">Version: {{ apiDetails.api_version }}</v-card-subtitle>
      <v-card-text>
        <v-divider></v-divider>
        <div class="my-6">
          <h3>Functionality</h3>
          <p class="functionality-text">{{ apiDetails.functionality }}</p>
        </div>
        <div class="my-6">
          <h3>API Call</h3>
          <v-chip class="api-call-container" color="blue" text-color="white">
            <code>{{ apiDetails.api_call }}</code>
          </v-chip>
        </div>
        <div class="my-6">
          <h3>API Arguments</h3>
          <v-list dense>
            <v-list-item v-for="(value, name) in apiDetails.api_arguments" :key="name">
              <v-list-item-content>
                <v-list-item-title>{{ name }}: {{ value }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="my-6">
          <h3>Example Code</h3>
          <div class="code-container" style="overflow-x: auto;">
            <pre class="language-python"><code>{{ apiDetails.example_code }}</code></pre>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
<script>
export default {
  computed: {
    apiDetails() {
      return this.$store.getters.getApiDetails;
    },
  },
};
</script>

<style scoped>
.headline {
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
}
.grey--text {
  color: #5f5f5f;
  font-size: 16px;
}
.code-container pre {
  margin-top: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.functionality-text {
  margin-top: 12px;
  font-size: 16px;
}

.api-call-container {
  margin-top: 12px;
  font-size: 16px;
}
</style>